import { Modal } from 'antd'
import { useBranch } from '../../../../hooks/useBranch'
import { Check, Input, InputFile, Select } from '../../../../components'
import { useEffect, useMemo, useState, type FC, type PropsWithChildren } from 'react'
import { type IContent } from '../../../../types/content'
import { useGetSubjectsByBranch } from '../../../../services/subject'
import { useGetTopicsBySubject } from '../../../../services/topic'
import { useCreateContent, useUpdateContent } from '../../../../services/content'
import { parseSelect } from '../../../../utils/parseSelect'
import { getIframeLink } from '../../utils'

type Props = PropsWithChildren<{
    folders: string[]
    selectedSubject?: IContent
    isOpen: boolean
    title: string
    onCancel: () => void
    onFinish: () => Promise<void>
}>

const ContentModal: FC<Props> = ({ folders, selectedSubject, isOpen, onCancel, title, onFinish }) => {
    const { branch } = useBranch()

    const [type, setType] = useState('link')
    const [subject, setSubject] = useState('')
    const [topic, setTopic] = useState('')
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [folder, setFolder] = useState('')
    const [downloadable, setDownloadable] = useState(false)
    const [isLink, setIsLink] = useState<boolean>(false)
    const [isIframe, setIsIframe] = useState<boolean>(false)
    const [iframeCode, setIframeCode] = useState<string>('')

    const [newFolder, setNewFolder] = useState<boolean>(false)

    const { subjects, loading: loadingSubjects } = useGetSubjectsByBranch(branch?._id as string)
    const { topics, loading: loadingTopics } = useGetTopicsBySubject(subject as string)

    const parsedSubjects = useMemo(() => parseSelect({
        array: subjects ?? [],
        labelKey: 'name',
        valueKey: '_id'
    }), [subjects, selectedSubject])

    const parsedTopics = useMemo(() => parseSelect({
        array: topics ?? [],
        labelKey: 'name',
        valueKey: '_id'
    }), [topics, selectedSubject])

    const parsedFolders = useMemo(() => [
        { label: '---', value: '' },
        ...(folders ?? []).map((folder) => ({
            label: folder,
            value: folder
        }))
    ], [folders])

    useEffect(() => {
        if (selectedSubject) {
            setType(selectedSubject.type)
            setName(selectedSubject.name)
            setUrl(selectedSubject.url)
            setFolder(selectedSubject.folder ?? '')
            setIsIframe(!!selectedSubject.iframeCode)
            setIframeCode(selectedSubject.iframeCode ?? '')
            setDownloadable(selectedSubject.downloadable)
            setSubject(selectedSubject.subject as string)
            setTopic(selectedSubject.topic as string)
            setIsLink(!selectedSubject.url?.includes('crossfactory.s3.eu-south-2.amazonaws'))
        }
    }, [selectedSubject])

    const { createContent, loading: creating } = useCreateContent()
    const { updateContent, loading: updating } = useUpdateContent()

    const resetForm = () => {
        setType('link')
        setName('')
        setUrl('')
        setDownloadable(false)
        setIsLink(false)
        setSubject('')
        setTopic('')
        setIframeCode('')
        setIsIframe(false)
    }

    const getResourceUrl = (url: string, type: string) => {
        setUrl(url)
        setType(type || 'application/pdf')
    }

    const onSave = async () => {
        const payload = {
            name,
            type,
            url: isIframe ? 'iframe' : url,
            branch: branch?._id || '',
            subject,
            topic,
            downloadable,
            folder,
            iframeCode: getIframeLink(iframeCode)
        }
        if (selectedSubject?._id) {
            await updateContent({ _id: selectedSubject?._id, ...payload })
        } else {
            await createContent(payload)
        }
        await onFinish()
        resetForm()
        onCancel()
    }

    const onCancelModal = () => {
        resetForm()
        onCancel()
    }

    return (
        <Modal
            open={isOpen}
            onOk={onSave}
            okText="Guardar"
            onCancel={onCancelModal}
            title={title}
            okButtonProps={{
                disabled: !name || !type || !branch || !subject || !topic,
                loading: creating || updating
            }}
        >
            {(!isLink && !isIframe) && <InputFile value={url} type="any" name={'Cargar'} fallbackUrl={getResourceUrl} />}
            <Check checked={isLink} name='Enlace' onChange={({ target }) => setIsLink(target.checked)} />
            <Check disabled={isLink} checked={isIframe} name='Iframe' onChange={({ target }) => setIsIframe(target.checked)} />

            <Input value={name} placeholder='Nombre' onChange={({ target }) => setName(target.value)} />
            {isIframe && (<Input placeholder='Pega el código del Iframe' onChange={({ target }) => setIframeCode(target.value)} value={iframeCode} />)}
            {(!isLink && !isIframe) && <Check checked={downloadable} name='Descargable' onChange={({ target }) => setDownloadable(target.checked)} />}
            {isLink && <Input value={url} placeholder='Url' onChange={({ target }) => setUrl(target.value)} />}
            <Select disabled={loadingSubjects} loading={loadingSubjects} value={subject} options={parsedSubjects} placeholder='Bloque' onChange={(id) => setSubject(id)} />
            <Select disabled={!subject || loadingTopics} loading={loadingTopics} value={topic} options={parsedTopics} placeholder='Tema' onChange={(id) => setTopic(id)} />
            <Check checked={newFolder} name='Crear carpeta nueva' onChange={({ target }) => setNewFolder(target.checked)} />

            {newFolder
                ? (
                    <Input placeholder='Carpeta' onChange={(e) => setFolder(e.target.value)} value={folder} />
                )
                : (
                    <Select placeholder="Carpeta" options={parsedFolders} onChange={setFolder} value={folder} />
                )}
        </Modal>
    )
}

export default ContentModal
