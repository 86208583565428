import { type IBranch } from './branch'
import { type Meeting } from './meeting'
import { type ISubject } from './subject'
import { type TTeacher } from './teacher'
export enum Kind {
  Presencial = 0,
  Online = 1,
}
export interface TSession {
  _id?: string
  active?: boolean
  description: string
  kind: Kind
  color: string
  date: Date
  spots: number
  meeting?: Meeting
  branch?: IBranch | string
  subject: ISubject | string
  teacher: TTeacher | string
  formattedDate?: string
}
