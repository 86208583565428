import { gql } from '@apollo/client'

export const MODIFY_USER_ALLOWEANCE = gql`
    mutation modifyUserAlloweance($input: UpdateUserAlloweanceInput!) {
        modifyUserAlloweance(input: $input) {
            _id
        }
    }
`

export const MASSIVE_USER_ALLOWEANCE = gql`
    mutation massiveAlloweanceSubjectAndTopic($input: String!) {
        massiveAlloweanceSubjectAndTopic(input: $input)
    }
`
