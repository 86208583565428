import { Collapse, Modal, Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { Screen } from '../../components'
// import { Link } from 'react-router-dom'

import './SubjectsPage.scss'
import { useUser } from '../../hooks/useUser'
import { useState, type SyntheticEvent } from 'react'
import { useGetBranchContent, useRemoveContent } from '../../services/content'
import { Link } from 'react-router-dom'
import { useBranch } from '../../hooks/useBranch'
import { type IContent } from '../../types/content'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ISubject } from '../../types/subject'
import { type ITopic } from '../../types/topic'
import ContentModal from './components/ContentModal/ContentModal'
import Folder from './components/Folder/Folder'
import { AddFolderModal } from './components/AddFolderModal/AddFolderModal'

const Panel = Collapse.Panel
const regex = /\.(docx|rtf|txt|pptx|pps|xlsx|xls|pdf)$/i

const SubjectsPage = () => {
  const { branch } = useBranch()
  const { refetch, grouped, folders: selectFolders } = useGetBranchContent(branch?._id)
  const { isAdmin, isSuperAdmin } = useUser()
  const [isOpen, setIsOpen] = useState(false)
  const { removeContent, loading } = useRemoveContent()

  const [removeModal, setRemoveModal] = useState(false)
  const [addFolderModal, setAddFolderModal] = useState(false)
  const [iframeModal, setIframeModal] = useState<string>()
  const [selectedContent, setSelectedContent] = useState<IContent>()

  const [selectedForFolder, setSelectedForFolder] = useState<string>()

  const renderHeader = (name: string, addFolders?: boolean, topicId?: string) => {
    const onAddFolder = () => {
      setSelectedForFolder(topicId)
      setAddFolderModal(true)
    }

    return (
      <div className='subject-header'>
        <p>{name}</p>
        {addFolders && (
          <button className='add-folder' onClick={onAddFolder}>
            Añadir carpeta
          </button>
        )}
      </div>
    )
  }

  const onCancel = () => {
    setSelectedContent(undefined)
    setIsOpen(false)
  }

  const onClickAddContent = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOpen(true)
  }

  const handleFinish = async () => {
    setSelectedContent(undefined)
    await refetch()
  }

  const handleRemove = async () => {
    await removeContent(selectedContent?._id as string)
    await refetch()
    setRemoveModal(false)
  }

  const openIframe = (iframeUrl: string) => {
    setIframeModal(iframeUrl)
  }

  return (
    <Screen id="subjects" title="Temario">
      {
        (isAdmin || isSuperAdmin) && (
          <div className='add-content-container'>
            <button className='add-content' onClick={onClickAddContent}>Añadir contenido</button>
          </div>
        )
      }
      <div className='subjects-content'>
        <Collapse>
          {Object.entries(grouped || {}).map(([subject, subjectContent]) => {
            const sortable = subjectContent as any
            const sortedObj = Object.keys(sortable)
              .sort()
              .reduce((acc: any, key) => {
                acc[key] = sortable[key]
                return acc
              }, {})

            return (
              <Panel header={renderHeader(subject)} key={subject}>
                <Collapse>
                  {Object.entries(sortedObj as any).map(([topic, topicContent]) => {
                    const [topicName, topicId] = topic.split('***')

                    const shouldShowTopic = true

                    const content = topicContent as IContent[]
                    if (!content || content.length === 0 || !shouldShowTopic) return null

                    const folders: string[] = []

                    content.forEach(({ folder }) => {
                      if (folder && !folders.includes(folder)) {
                        folders.push(folder)
                      }
                    })

                    return (
                      <Panel header={renderHeader(topicName, true, topicId)} key={topic}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {folders.map((folder) => (
                            <Folder openIframe={openIframe} refresh={refetch} openModal={setIsOpen} folder={folder} key={folder} content={content} loading={loading} setSelectedContent={setSelectedContent} setRemoveModal={setRemoveModal} />
                          ))}
                          {content.filter(({ folder }) => !folder).map((it) => (
                            <div key={it.name} className='content-line'>
                              {it.iframeCode && <button onClick={() => openIframe(it.iframeCode!)}>{it.name}</button>}
                              {(!it.iframeCode && !regex.test(it.url)) && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                              {(!it.iframeCode && regex.test(it.url)) && <Link to={`/doc/${it.url.split('/')[3]}/${encodeURIComponent(it.type)}/${it.name}?isEnabled=${it.downloadable}`} >{it.name}</Link>}
                              {it.type === 'image' && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                              {it.downloadable && <a className='download-content' href={it.url}><DownloadOutlined /></a>}

                              {isAdmin && (
                                <div className='line-actions'>
                                  <button>
                                    <IconEdit color="#4cae4c" size={20} onClick={() => {
                                      console.log(it)
                                      setSelectedContent({
                                        ...it,
                                        subject: (it.subject as ISubject)._id!,
                                        topic: (it.topic as ITopic)._id!
                                      })
                                      setTimeout(() => {
                                        setIsOpen(true)
                                      }, 500)
                                    }} />
                                  </button>
                                  <button onClick={() => {
                                    setRemoveModal(true)
                                    setSelectedContent(it)
                                  }}>
                                    <IconTrash color="#F47174" size={20} />
                                  </button>
                                  {loading && (
                                    <Spin />
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </Panel>
                    )
                  })}
                </Collapse>
              </Panel>
            )
          })}
        </Collapse>
      </div>
      <Modal
        open={removeModal}
        onCancel={() => setRemoveModal(false)}
        okButtonProps={{
          danger: true,
          loading
        }}
        onOk={handleRemove}
        okText="Eliminar"
        title="Eliminar temario"
      >
        {'Esto eliminará este contenido y no podrás recuperarlo". ¿Estas seguro de que quieres continuar?'}
      </Modal>
      <Modal
        open={!!iframeModal}
        onCancel={() => setIframeModal(undefined)}
        onOk={() => setIframeModal(undefined)}
        okText="Cerrar"
        title=""
      >
        {!!iframeModal && (
          <>
            <iframe src={`${iframeModal}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{ width: 500, height: 500 }} title="CLASE 2. Angeles. CE. 14-12-24 + DER Y LIBERTADES CALSE º"></iframe><script src="https://player.vimeo.com/api/player.js"></script>
          </>
        )}
      </Modal>
      <AddFolderModal refetch={refetch} isOpen={addFolderModal} onClose={() => setAddFolderModal(false)} selectedForFolder={selectedForFolder} />
      <ContentModal folders={selectFolders} selectedSubject={selectedContent} onFinish={handleFinish} isOpen={isOpen} title='Contenido' onCancel={onCancel} />
    </Screen>
  )
}

export { SubjectsPage }
