import { IconEdit, IconFolder, IconFolderOpen, IconTrash } from '@tabler/icons-react'
import { type FC, useState } from 'react'
import { type IContent } from '../../../../types/content'
import { Link } from 'react-router-dom'
import { DownloadOutlined } from '@ant-design/icons'
import { useUser } from '../../../../hooks/useUser'
import { type ISubject } from '../../../../types/subject'
import { type ITopic } from '../../../../types/topic'
import { Input, Modal, Spin } from 'antd'
import { type bool } from 'aws-sdk/clients/signer'
import { useEditFolderContent, useRemoveFolderContent } from '../../../../services/content'
import { type ApolloQueryResult } from '@apollo/client'

interface Props {
    content: IContent[]
    folder: string
    loading: boolean
    setSelectedContent: any
    setRemoveModal: (val: boolean) => void
    openModal: (val: bool) => void
    openIframe: (val: string) => void
    refresh: () => Promise<ApolloQueryResult<any>>
}

const regex = /\.(docx|rtf|txt|pptx|pps|xlsx|xls|pdf)$/i

const Folder: FC<Props> = ({ content, openModal, refresh, folder, openIframe, loading, setSelectedContent, setRemoveModal }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isRemoving, setIsRemoving] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [folderName, setFolderName] = useState('')
    const { isAdmin } = useUser()

    const { editFolder, loading: editing } = useEditFolderContent()
    const { removeFolder, loading: removing } = useRemoveFolderContent()

    const folderContent = content.filter(({ folder: fld, isHidden }) => (folder === fld) && !isHidden)

    const onCloseEditfolder = () => {
        setIsEditing(false)
        setFolderName('')
    }

    const onEditFolder = async () => {
        const topic = content[0].topic as ITopic
        await editFolder({
            oldName: folder,
            newName: folderName,
            topic: topic._id as string
        })
        await refresh()
        setIsEditing(false)
    }

    const onRemoveFolder = async () => {
        const topic = content[0].topic as ITopic
        await removeFolder({
            folder,
            topic: topic._id as string
        })
        await refresh()
        setIsRemoving(false)
    }

    return (
        <div className='folder-style'>
            <button className='folders' onClick={() => setIsOpen((prev) => !prev)}>
                <div>
                    {isOpen ? <IconFolderOpen /> : <IconFolder />}
                    <p>{folder}</p>
                </div>
                <div>
                    <IconEdit color="#4cae4c" size={20} onClick={() => setIsEditing(true)} />
                    <button onClick={() => {
                        setIsRemoving(true)
                    }}>
                        <IconTrash color="#F47174" size={20} />
                    </button>
                </div>
            </button>
            {isOpen && (
                folderContent.map((it) => (
                    <div key={it.name} className='content-line' style={{ marginLeft: 30 }}>
                        {it.iframeCode && <button onClick={() => openIframe(it.iframeCode!)}>{it.name}</button>}
                        {(!it.iframeCode && !regex.test(it.url)) && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                        {(!it.iframeCode && regex.test(it.url)) && <Link to={`/doc/${it.url.split('/')[3]}/${encodeURIComponent(it.type)}/${it.name}?isEnabled=${it.downloadable}`} >{it.name}</Link>}
                        {it.type === 'image' && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                        {it.downloadable && <a className='download-content' href={it.url}><DownloadOutlined /></a>}

                        {isAdmin && (
                            <div className='line-actions'>
                                <button>
                                    <IconEdit color="#4cae4c" size={20} onClick={() => {
                                        setSelectedContent({
                                            ...it,
                                            subject: (it.subject as ISubject)._id!,
                                            topic: (it.topic as ITopic)._id!
                                        })
                                        setTimeout(() => {
                                            openModal(true)
                                        }, 500)
                                    }} />
                                </button>
                                <button onClick={() => {
                                    setRemoveModal(true)
                                    setSelectedContent(it)
                                }}>
                                    <IconTrash color="#F47174" size={20} />
                                </button>
                                {loading && (
                                    <Spin />
                                )}
                            </div>
                        )}
                    </div>
                ))
            )}
            <Modal
                open={isRemoving}
                onCancel={() => setIsRemoving(false)}
                okButtonProps={{
                    danger: true,
                    loading: removing
                }}
                onOk={onRemoveFolder}
                okText="Eliminar"
                title="Eliminar carpeta"
            >
                {'Esto solo eliminará la carpeta y dejará su contenido fuera, no borrará el contenido de la misma.'}
            </Modal>
            <Modal
                open={isEditing}
                onCancel={onCloseEditfolder}
                okButtonProps={{
                    loading: editing
                }}
                onOk={onEditFolder}
                okText="Guardar"
                title="Editar carpeta"
            >
                {'Se cambiará el nombre de la carpeta. Esta acción no afectará al contenido'}
                <Input style={{ marginTop: 10 }} placeholder='Nombre de la carpeta' value={folderName} onChange={(e) => setFolderName(e.target.value)} />
            </Modal>
        </div>
    )
}

export default Folder
