import { useMutation, useQuery } from '@apollo/client'
import { GET_USER_ALLOWEANCE } from './queries'
import { MASSIVE_USER_ALLOWEANCE, MODIFY_USER_ALLOWEANCE } from './mutations'
import { type IUserAlloweance } from '../../types/userAlloweance'

export const useGetUserAlloweance = (input?: string) => {
  const { data, loading, refetch } = useQuery(GET_USER_ALLOWEANCE, {
    fetchPolicy: 'no-cache',
    variables: { input },
    skip: !input
  })

  return { alloweance: data?.getUserAlloweance as IUserAlloweance, loading, refetch }
}

interface ModifyParams {
  _id: string
  tests: string[]
  subjects: string[]
  topics: string[]
  content: string[]
  contentTopics: string[]
  type: string
}

export const useModifyAlloweance = () => {
  const [action, { data, loading }] = useMutation(MODIFY_USER_ALLOWEANCE)

  const modifyAlloweance = async (input: ModifyParams) => {
    const response = await action({
      variables: {
        input
      }
    })

    return response.data.modifyUserAlloweance as IUserAlloweance
  }

  return { loading, modifyAlloweance, data }
}

export const useMassiveAlloweance = () => {
  const [action, { data, loading, error }] = useMutation(MASSIVE_USER_ALLOWEANCE)

  console.log(JSON.stringify(error, null, 2))

  const massiveAlloweance = async (input: string) => {
    const response = await action({
      variables: {
        input
      }
    })

    return response.data.massiveAlloweanceSubjectAndTopic as string
  }

  return { loading, massiveAlloweance, data }
}
