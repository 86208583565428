import { Calendar, Modal } from 'antd'
import { format } from 'date-fns'

import { Box, Screen } from '../../components'
import { useBranch } from '../../hooks/useBranch'
import { getPercentageCorrect, useGetDashboardData } from '../../services/userQuestion'

import './DashboardPage.scss'
import { SubjectResult } from './components'
import { FailedQuestions } from './components/FailedQuestions'
import { TestResults } from './components/TestResults'
import { useGetSessionsDashboard } from '../../services/session'
import { useState } from 'react'
import { type TSession } from '../../types/session'
import { Loader } from '../../components/Loader/Loader'

interface Props {
  marked: boolean
}

const CalendarMarker = ({ marked }: Props) => {
  return <div className={marked ? 'calendar-mark' : ''} />
}

const DashboardPage = () => {
  const { branch } = useBranch()

  const { sessions, loading } = useGetSessionsDashboard()

  const { dashboardData } = useGetDashboardData()

  const [modalEvents, setModalEvents] = useState(false)

  const [selectedEvents, setSelectedEvents] = useState<TSession[]>()

  const worst = dashboardData[0]
  const best = dashboardData[dashboardData.length - 1]

  const sessionsStrings = (sessions || [])?.map((s) => format(new Date(s.date), 'dd/MM/yyyy'))

  const handleSelectCalendar = (date: Date) => {
    const formatted = format(date, 'dd/MM/yyyy')

    const events = sessions?.filter((session) => format(new Date(session.date), 'dd/MM/yyyy') === formatted)

    if ((events || []).length > 0) {
      setSelectedEvents(events)
      setModalEvents(true)
    }
  }

  return (
    <Screen id="dashboard" title={branch?.name ?? 'Dashboard'}>
      <div className='section-container'>
        <Box flex={3} title="Resultados en tests" defaultCollpsed>
          <TestResults />
        </Box>
        <Box flex={1} title="Mejor materia" defaultCollpsed>
          {best && (
            <SubjectResult type="success" name={best?.[0]} score={getPercentageCorrect(best?.[1])} />
          )}
        </Box>
        <Box flex={1} title="Peor materia" defaultCollpsed>
          {worst && (
            <SubjectResult type="danger" name={worst?.[0]} score={getPercentageCorrect(worst?.[1])} />
          )}
        </Box>
      </div>
      <div className='section-container'>
        <div className='calendar'>
          {loading
            ? (
              <Loader size={100} />
            )
            : (
              <>
                <Box flex={2} title="Calendario" defaultCollpsed>
                  <Calendar
                    rootClassName='cale-root'
                    fullscreen={false}
                    onSelect={(e: any) => handleSelectCalendar(e.$d)}
                    className='calendar-comp'
                    cellRender={(date: any) => <CalendarMarker marked={sessionsStrings.includes(format(new Date(date.$d), 'dd/MM/yyyy'))} />}
                  />
                </Box>
              </>
            )}
        </div>
        <Box flex={3} title="Preguntas a reforzar" defaultCollpsed>
          <FailedQuestions />
        </Box>
        {selectedEvents?.[0] && (
          <Modal
            open={modalEvents}
            onCancel={() => setSelectedEvents(undefined)}
            okButtonProps={{
              style: {
                display: 'none'
              }
            }}
            title={`Calendario dia ${selectedEvents![0].formattedDate ?? format(new Date(selectedEvents![0].date!), 'dd/MM/yyyy HH:mm')}`}
          >
            {selectedEvents.map((ev) => (
              `♦ ${ev.description}`
            ))}
          </Modal>

        )}
      </div>
    </Screen>
  )
}

export { DashboardPage }
