import { gql } from '@apollo/client'

export const GET_SESSIONS_DASHBOARD = gql`
query getAllActiveSessionsDashBoard ($input: String!){
  getAllActiveSessionsDashBoard (input: $input){
      _id
      formattedDate
      date
      kind
      join_url
      description
      teacher { name }
      meeting {join_url}
  }
}
`

export const GET_SESSIONS = gql`
query getAllActiveSessions ($input: String!){
  getAllActiveSessions(input: $input){
      _id
      date
      kind
      description
      color
      spots
      join_url
      start_url
      formattedDate
      teacher { 
        _id 
        name 
      }
      branch { 
        _id 
        name 
       }
      subject { 
        _id 
        name 
       }
      meeting{
        _id
        id
        join_url
        start_url
        duration
        record_type
      }
  }
}
`
